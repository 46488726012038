/* Utilities */
.grid-margin {
  margin-bottom: $grid-gutter-width;
}

.grid-margin-sm-0 {
  @media (min-width: 576px) {
    margin-bottom: 0;
  }
}

.grid-margin-md-0 {
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.grid-margin-lg-0 {
  @media (min-width: 992px) {
    margin-bottom: 0;
  }
}

.grid-margin-xl-0 {
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}

.img-lg {
  width: 92px;
  height: 92px;
}

.img-md {
  width: 60px;
  height: 60px;
}

.img-sm {
  width: 40px;
  height: 40px;
}

.img-xs {
  width: 37px;
  height: 37px;
}

.img-ss {
  width: 26px;
  height: 26px;
}

.img-bordered {
  border: 2px solid $white;
}

.image-layers {
	position: relative;
	min-height: 24px;
	.image-layer-item {
		position: absolute;
    border: 2px solid $white;
    width: 24px;
    height: 24px;
	}
	@for $i from 1 through 10 {
    :nth-child(#{ $i }) {
      z-index: #{ 10 - ($i - 1) };
      left: #{ ($i - 1) * 15 }px;
    }
	}
	.profile-image-text {
		line-height: 20px;
	}
}

.profile-image-text {
  line-height: 24px;
  text-align: center;
  color: $white;
  font-size: 1rem;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  > .card {
    width: 100%;
    min-width: 100%;
  }
}

.border-right-sm {
  @media (min-width: 576px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-md {
  @media (min-width: 768px) {
    border-right: $border-width solid $border-color;
  }
}

.border-right-lg {
  @media (min-width: 992px) {
    border-right: $border-width solid $border-color;
  }
}

.border-left-sm {
  @media (min-width: 576px) {
    border-left: $border-width solid $border-color;
  }
}

.border-strong {
  border-color: darken($border-color,80%);
}

.count-wrapper {
  position: relative;

  .count {
    position: absolute;
    width: auto;
    min-width: 8px;
    min-height: 8px;
    padding: 2px 4px;
    font-size: 75%;
    line-height: 1;
    vertical-align: middle;
    @include border-radius(100%);
    color: $white;
    font-weight: 500;

    &.top-right {
      top: -5px;
      right: 0;
    }

    &.bottom-right {
      bottom: -5px;
      right: 0;
    }

    &.bottom-left {
      bottom: -5px;
      left: 0;
    }

    &.top-left {
      top: -5px;
      left: 0;
    }
  }
}

.border-left-md {
  @media (min-width: 768px) {
    border-left: $border-width solid $border-color;
  }
}

.border-left-lg {
  @media (min-width: 992px) {
    border-left: $border-width solid $border-color;
  }
}

.text-gray {
  color: #636363;
}

.text-black {
  color: $black;
}

.flex-grow {
  flex-grow: 1;
}

.ellipsis {
  max-width: 95%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.no-wrap {
  white-space: nowrap;
}

.bg-transparent {
  background: transparent;
}
@each $color, $value in $theme-colors {
  .bg-inverse-#{$color} {
    @include bg-inverse-variant($value);
  }
}

.disc {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}